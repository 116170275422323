import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp, faArrowTrendDown, faMoneyBill, faBuildingColumns } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-bootstrap';
import axios from 'axios';
import './DashboardPage.css';
import '../../assets/global.css';


function DashboardPage() {
    const navigate = useNavigate();
    const [allTradingCards, setallTradingCards] = useState([]);
    const [myTradingCards, setmyTradingCards] = useState([]);
    const [allNonActiveTradingCards, setAllNonActiveTradingCards] = useState([]);

    const lastTab = sessionStorage.getItem('LastTab');
    const lastSearchTerm = sessionStorage.getItem('LastSearchTerm');

    const [activeTab, setActiveTab] = useState(lastTab || 'AllTrades');

    const [userData, setUserData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortCriteria, setSortCriteria] = useState("lastUpdated");
    const [sortOrder, setSortOrder] = useState('descending');
    const [searchTerm, setSearchTerm] = useState(lastSearchTerm || '');


    const userId = sessionStorage.getItem('userId');
    const token = sessionStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            navigate("/login");
        }
    }, [token, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const headers = {
                    'Authorization': `Bearer ${token}`
                };

                const userDataResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/getUserData`, { headers });
                setUserData(userDataResponse.data);

                const allTradingCardsResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/AllTrades`, { headers });
                setallTradingCards(allTradingCardsResponse.data);

                const allNonActiveTradingCardsResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/AllTradesNonActive`, { headers });
                setAllNonActiveTradingCards(allNonActiveTradingCardsResponse.data);

                const myTradingCardsResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/MyTrades`, { headers });
                setmyTradingCards(myTradingCardsResponse.data);

            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Something went wrong getting your data. Try loging out and in again.");
            } finally {
                setLoading(false);
            }
        };


        fetchData();
    }, [userId, token]);

    const sortSignals = (signals, criteria, order) => {
        if (!criteria) return signals;

        return [...signals].sort((a, b) => {
            let comparison = 0;

            if (criteria === 'type') {
                comparison = a.type - b.type;
            } else if (criteria === 'lastUpdated') {
                comparison = new Date(a.lastUpdated) - new Date(b.lastUpdated);
            }
            if (order === 'descending') {
                comparison = -comparison;
            }

            return comparison;
        });
    };

    const filterSignals = (signals, term) => {
        
        if (!term.trim()) {
            return signals;
        }
        const upperCaseTerm = term.toUpperCase().trim();
        try {
            sessionStorage.setItem('LastSearchTerm', upperCaseTerm);
            console.log("LastSearchTerm set in sessionStorage:", upperCaseTerm);
        } catch (error) {
        }
        const filteredSignals = signals.filter(signal => signal.pair.toUpperCase().includes(upperCaseTerm));
        return filteredSignals;
    };
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="dashboard-container">
            <div className="main-content">
                <div className="sorting-controls">
                    <select onChange={(e) => setSortCriteria(e.target.value)} value={sortCriteria}>
                        <option value="">Select Sorting</option>
                        <option value="type">Sort by Type</option>
                        <option value="lastUpdated">Sort by Last Updated</option>
                    </select>
                    <select onChange={(e) => setSortOrder(e.target.value)} value={sortOrder}>
                        <option value="ascending">Ascending</option>
                        <option value="descending">Descending</option>
                    </select>
                    <input
                        type="text"
                        placeholder="Search signal..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <Tabs activeTab={activeTab} setActiveTab={setActiveTab} userData={userData} />
                <TradingCardsContainer
                    activeTab={activeTab}
                    allTradingCards={sortSignals(filterSignals(allTradingCards, searchTerm), sortCriteria, sortOrder)}
                    weeklyReport={sortSignals(filterSignals(allNonActiveTradingCards, searchTerm), sortCriteria, sortOrder)}
                    myTradingCards={sortSignals(filterSignals(myTradingCards, searchTerm), sortCriteria, sortOrder)}
                />
            </div>
        </div>
    );
}

function Tabs({ activeTab, setActiveTab, userData }) {

    const isCreatorOrAdmin = userData.roles[0] === 'Creator' || userData.roles[0] === 'Admin';

    return (
        <div className="tabs">

            <button className={activeTab === 'AllTrades' ? 'active' : ''} onClick={() => {
                    setActiveTab('AllTrades');
                    sessionStorage.setItem('LastTab', "AllTrades");
                    }
                }>
                Active Trades
            </button>
            <button className={activeTab === 'WeeklyReport' ? 'active' : ''}
                onClick={() => {
                    setActiveTab('WeeklyReport');
                    sessionStorage.setItem('LastTab', "WeeklyReport");
                    }
                }>
                Finished Trades
            </button>
            {isCreatorOrAdmin && (
                <button className={activeTab === 'MyTrades' ? 'active' : ''} onClick={() => {
                    setActiveTab('MyTrades');
                    sessionStorage.setItem('LastTab', "MyTrades");
                    }
                }>
                    My Trades
                </button>
            )}
        </div>
    );
}

function TradingCardsContainer({ activeTab, allTradingCards, weeklyReport, myTradingCards }) {

    return (
        <div className="trading-cards-container">
            {
                activeTab === 'AllTrades' && allTradingCards.length === 0 ? (
                    <h1>There are no active trades yet.</h1>
                ) : (
                    activeTab === 'AllTrades' && (
                        allTradingCards.map((signal, index) => (
                            <AllTrades key={index} card={signal} />
                        ))
                    )
                )
            }
            {
                activeTab === 'MyTrades' && myTradingCards.length === 0 ? (
                    <h1>You have not created any trades yet.</h1>
                ) : (
                    activeTab === 'MyTrades' && (
                        myTradingCards.map((signal, index) => (
                            <MyTrades key={index} card={signal} />
                        ))
                    )
                )
            }
            {
                activeTab === 'WeeklyReport' && weeklyReport.length === 0 ? (
                    <h1>No trades have closed so far.</h1>
                ) : (
                    activeTab === 'WeeklyReport' && (
                        weeklyReport.map((signal, index) => (
                            <WeeklyReport key={index} card={signal} />
                        ))
                    )
                )
            }
        </div>
    );
}

function AllTrades({ card }) {

    const formatDate = (dateString) => {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };


    return (
        <NavLink to={`/card-view/${card.id}`} className={"Nav-Link"}>
            <div className='trading-card'>
                <h2>{card.pair}</h2>
                <p>{formatDate(card.lastUpdated)}</p>
                {card.tradeDirection === 'buy' ? (
                    <Tooltip placement="top" title="Buy Signal">
                        <FontAwesomeIcon icon={faArrowTrendUp} className='trend-arrow' />
                    </Tooltip>
                ) : (
                    <Tooltip placement="top" title="Sell Signal">
                        <FontAwesomeIcon icon={faArrowTrendDown} className='trend-arrow' />
                    </Tooltip>
                )}
                {card.type === "1" ? (
                    <Tooltip placement="top" title="Forex Trade">
                        <FontAwesomeIcon icon={faMoneyBill} className='trend-arrow' />
                    </Tooltip>
                ) : (
                    <Tooltip placement="top" title="Indices Trade">
                        <FontAwesomeIcon icon={faBuildingColumns} className='trend-arrow' />
                    </Tooltip>
                )}
            </div>
        </NavLink>
    );
}

function WeeklyReport({ card }) {

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    return (
        <NavLink to={`/card-view/${card.id}`} className={"Nav-Link"}>
            <div className='trading-card'>
                <h2>{card.pair}</h2>
                <p>{formatDate(card.closedDate)}</p>
                {card.tradeDirection === 'buy' ? (
                    <Tooltip placement="top" title="Buy Signal">
                        <FontAwesomeIcon icon={faArrowTrendUp} className='trend-arrow' />
                    </Tooltip>
                ) : (
                    <Tooltip placement="top" title="Sell Signal">
                        <FontAwesomeIcon icon={faArrowTrendDown} className='trend-arrow' />
                    </Tooltip>
                )}
                {card.type === "1" ? (
                    <Tooltip placement="top" title="Forex Trade">
                        <FontAwesomeIcon icon={faMoneyBill} className='trend-arrow' />
                    </Tooltip>
                ) : (
                    <Tooltip placement="top" title="Indices Trade">
                        <FontAwesomeIcon icon={faBuildingColumns} className='trend-arrow' />
                    </Tooltip>
                )}
            </div>
        </NavLink>
    );
}

function MyTrades({ card }) {

    let takeProfit = card.takeProfit;
    const parsedTakeProfit = JSON.parse(takeProfit);
    const formatDate = (dateString) => {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    return (
        <NavLink to={`/editcard/${card.id}`} className={"Nav-Link"}>
            <div className='trading-card'>
                <h2>{card.pair}</h2>
                <p>{formatDate(card.creationDate)}</p>
                <p><b>Entry Price: </b>{card.entryPrice}</p>
                <p className='stop-loss-text'><b>Stop Loss: </b>{card.stopLoss}</p>
                {
                    parsedTakeProfit.map((tp, index) => (
                        <p key={index} className='take-profit-text'><b>Take Profit {index + 1}:</b> {tp}</p>
                    ))
                }
                <p><b>Exit price:</b> {card.exitPrice}</p>
            </div>
        </NavLink>
    );
}

export default DashboardPage;
