import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            setUserRole(decodedToken.role); 
        }
    }, []);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    }

    console.log(userRole)
    const showNewCardLink = userRole === 'Creator' || userRole === 'Admin';


    return (
        <header className="header">
            <div className="logo">AGTradingTrades</div>
            <div onClick={handleToggle} className="menu-icon">
                <div className={isOpen ? "bar open bar1" : "bar bar1"}></div>
                <div className={isOpen ? "bar open bar2" : "bar bar2"}></div>
                <div className={isOpen ? "bar open bar3" : "bar bar3"}></div>
            </div>
            <nav className={isOpen ? "navigation navigation-open" : "navigation"}>
            {path === "/login" || path === "/signup" ? (
                    <NavLink exact to="/" activeClassName="active-link">Home</NavLink>
                ) : path === "/dashboard" || path.includes("/card-view") || path.includes("/capitalPlayground") || path.includes("/traderprofile") || path.includes("/profile") || path.includes("/searchtraders")|| path.includes("/newblogpost") || path.includes("/editcard") || path.includes("/newcard") || path.includes("/payment")? (
                    <>
                        <NavLink to="/dashboard" activeClassName="active-link">Dashboard</NavLink>
                        <NavLink to="/traderprofile" activeClassName="active-link">Traders</NavLink>
                        <NavLink to="/profile" activeClassName="active-link">Profile</NavLink>
                        <NavLink to="/capitalPlayground" activeClassName="active-link">Capital Playground</NavLink>
                        {showNewCardLink && <NavLink to="/newblogpost" activeClassName="active-link">New Post</NavLink>}
                        {showNewCardLink && <NavLink to="/newcard" activeClassName="active-link">New Card</NavLink>}
                        <NavLink to="/login" activeClassName="active-link">Logout</NavLink>
                    </>
                ) : (
                    <>
                        <NavLink exact to="/" activeClassName="active-link">Home</NavLink>
                        <NavLink to="/about" activeClassName="active-link">About</NavLink>
                        <NavLink to="/contact" activeClassName="active-link">Contact</NavLink>
                        <NavLink to="/login" activeClassName="active-link">Login</NavLink>
                    </>
                )}
            </nav>
        </header>
    );
}

export default Header;
