import React from 'react';
import './TermsOfService.css';

function TermsOfServicePage() {
    return (
        <div className="terms-of-service-container">
            {/* Introduction Section */}
            <div className="terms-of-service-intro" id="introduction">
                <h1>Terms of Service</h1>
                <div className="terms-of-service-content">
                    <p>Welcome to AGTT. By accessing or using our website agtradingtrades.com, you agree to comply with and be bound by these Terms of Service. Please read these terms carefully.</p>
                </div>
            </div>

            {/* Acceptance of Terms Section */}
            <div className="terms-of-service-section" id="acceptance">
                <h2>Acceptance of Terms</h2>
                <div className="terms-of-service-content">
                    <p>By accessing or using our Site, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use the Site.</p>
                </div>
            </div>

            {/* User Responsibilities Section */}
            <div className="terms-of-service-section" id="user-responsibilities">
                <h2>User Responsibilities</h2>
                <div className="terms-of-service-content">
                    <p>You agree to use the Site only for lawful purposes and in a manner that does not infringe upon the rights of, restrict, or inhibit anyone else's use and enjoyment of the Site. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.</p>
                </div>
            </div>

            {/* Intellectual Property Section */}
            <div className="terms-of-service-section" id="intellectual-property">
                <h2>Intellectual Property</h2>
                <div className="terms-of-service-content">
                    <p>All content and materials on the Site, including but not limited to text, graphics, logos, and software, are the property of AGTT or its content suppliers and are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any content without our express written consent.</p>
                </div>
            </div>

            {/* Limitation of Liability Section */}
            <div className="terms-of-service-section" id="limitation-of-liability">
                <h2>Limitation of Liability</h2>
                <div className="terms-of-service-content">
                    <p>To the fullest extent permitted by law, AGTT shall not be liable for any indirect, incidental, special, or consequential damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data or use, arising from or related to your use of the Site.</p>
                </div>
            </div>

            {/* Changes to Terms Section */}
            <div className="terms-of-service-section" id="changes">
                <h2>Changes to Terms</h2>
                <div className="terms-of-service-content">
                    <p>We may update these Terms of Service from time to time. We will notify you of any changes by posting the new Terms of Service on this page. You are advised to review this page periodically for any changes. Changes to these Terms of Service are effective when they are posted on this page.</p>
                </div>
            </div>

            {/* Contact Information Section */}
            <div className="terms-of-service-section" id="contact">
                <h2>Contact Us</h2>
                <div className="terms-of-service-content">
                    <p>If you have any questions about these Terms of Service, please contact us:</p>
                    <ul>
                        <li><strong>By Email:</strong> agtradingtrades@gmail.com</li>
                        <li><strong>By Visiting This Page on Our Website:</strong> agtradingtrades.com/contact</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default TermsOfServicePage;
