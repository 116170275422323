import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../SignUpPage/SignUpPage.css';

function EmailVerificationPage() {
    const [verificationStatus, setVerificationStatus] = useState('');

    const { token } = useParams();

    useEffect(() => {
            console.log('Making verification request');
            fetch(`https://trading-website-5b1479c37ccb.herokuapp.com/verify-email?token=${token}`)
                .then(response => response.text())
                .then(data => {
                    console.log('Verification response:', data);
                    setVerificationStatus(data);
                })
                .catch(error => console.error('Error:', error));
    
    }, [token]);

    return (
        <div className="signup-container">
            <div className="signup-card">
                <h1>Email Verification</h1>
                <p className="verification-status">{verificationStatus}</p>
            </div>
        </div>
    );
}

export default EmailVerificationPage;
