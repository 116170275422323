import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faClock, faQuestionCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faTelegram, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import './ContactPage.css';
import '../../assets/global.css';

const ContactPage = () => {

    const [formStatus, setFormStatus] = useState(null);


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://trading-website-5b1479c37ccb.herokuapp.com/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            console.log('Submission Successful', data);
            setFormStatus(data); // Set the status with the response from the server
        } catch (error) {
            console.error('Error during fetch operation', error);
            setFormStatus({ status: 'error', message: 'Submission failed! Please try again later.' }); // Handle submission error
        }
    };

    return (
        <div className="contact-container">

            <div className="contact-header">
                <h1>Contact Us</h1>
                <p>We're here to help and answer any question you might have. Reach out to us and we'll respond as soon as we can.</p>
            </div>

            <div className="contact-form">
            {formStatus && <p className={`form-status ${formStatus.status}`}>{formStatus.message}</p>}

            <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="name">Full Name</label>
                        <input 
                            type="text" 
                            id="name" 
                            name="name" 
                            placeholder='Your name here'
                            value={formData.name}
                            onChange={handleChange} 
                            required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="email">Email Address</label>
                        <input 
                            type="email" 
                            id="email" 
                            name="email" 
                            placeholder='Your email here'
                            value={formData.email}
                            onChange={handleChange} 
                            required />
                    </div>
                    <div className="input-group">
                        <label htmlFor="message">Your Message</label>
                        <textarea 
                            id="message" 
                            name="message" 
                            placeholder='Your msg here'
                            rows="5" 
                            value={formData.message}
                            onChange={handleChange} 
                            required></textarea>
                    </div>
                    <button type="submit" className="submit-button">Submit</button>
                </form>
            </div>

            <div className="contact-info">
                <h2>Reach Us Directly <FontAwesomeIcon className="icon" icon={faPhone} /></h2>
                <p><strong>Address:</strong> 123 Trading St., Finance City, 12345</p>
                <p><strong>Phone:</strong> +1 (123) 456-7890</p>
                <p><strong>Email:</strong> support@tradingplatform.com</p>
            </div>

            <div className="office-hours">
                <h2>Office Hours <FontAwesomeIcon className="icon" icon={faClock} /></h2>
                <p>Monday - Friday: 9am - 5pm</p>
                <p>Saturday: 10am - 2pm</p>
                <p>Sunday: Closed</p>
            </div>

            <div className="faq-section">
                <h2>Frequently Asked Questions <FontAwesomeIcon className="icon" icon={faQuestionCircle} /></h2>
                <p>For common queries, please check our <a href="/faq">FAQ page</a>.</p>
            </div>

            <div className="social-media-links">
            <a href="https://discord.gg/vXHZx5cm2U" className="link-button-contact">
                        <FontAwesomeIcon className="icon-Contact" icon={faDiscord} />
                    </a>
            <a href="https://discord.gg/vXHZx5cm2U" className="link-button-contact">
                        <FontAwesomeIcon className="icon-Contact" icon={faTelegram} /> 
                    </a>
            <a href="https://discord.gg/vXHZx5cm2U" className="link-button-contact">
                        <FontAwesomeIcon className="icon-Contact" icon={faXTwitter} /> 
                    </a>
            <a href="https://discord.gg/vXHZx5cm2U" className="link-button-contact">
                        <FontAwesomeIcon className="icon-Contact" icon={faInstagram} /> 
                    </a>
            </div>

            <div className="testimonials">
                <h2>What Our Users Say <FontAwesomeIcon className="icon" icon={faUsers} /></h2>
                <p>"Amazing trading platform! Highly recommended." - John Doe</p>
                {/* More testimonials can be added here */}
            </div>

        </div>
    );
}

export default ContactPage;
