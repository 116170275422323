import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PaymentPage.css';
import '../../assets/global.css';

function PaymentPage() {

    const [userData, setUserData] = useState([]);

    const userId = sessionStorage.getItem('userId');
    const token = sessionStorage.getItem('token');
    
    useEffect(() => {
        const fetchUserEmail = async () => {
            try {
                const headers = {
                    'Authorization': `Bearer ${token}`
                };

                const userDataResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/getUserData`, { headers });
                setUserData(userDataResponse.data.user.email);
                
            } catch (err) {
                console.error("Error fetching data:", err); 
            } 
        };



        fetchUserEmail();
    }, [userId, token]);

    useEffect(() => {

        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []); 


    return (
        <div>
            <stripe-pricing-table
                pricing-table-id="prctbl_1OOM4EIJwLf9B0hfEYB3LnGK"
                publishable-key="pk_test_51OAaxzIJwLf9B0hf6u0wj0kUag90TFQGszh5qMAfw0dq8kcqPT69Srz3iLiBQtcGSfBacgTFeidhugbxuMGKGR1s00RxBKyAsg"
                customer-email={userData}
                >
            </stripe-pricing-table>
        </div>
    );
}

export default PaymentPage;
