import React, { useState } from 'react';
import axios from 'axios'; // Import Axios for making HTTP requests
import './NewBlogPost.css';
import '../../assets/global.css';


function NewBlogPost() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [message, setMessage] = useState(null); // State to hold success or error message

    const userId = sessionStorage.getItem('userId');
    //const token = sessionStorage.getItem('token');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send POST request to backend API to add new post
            const response = await axios.post('https://trading-website-5b1479c37ccb.herokuapp.com/newpost', {
                title: title,
                content: content,
                creatorId: userId
            });
            console.log('New post added:', response.data);
            // Reset form fields
            setTitle('');
            setContent('');
            setMessage('Post added successfully!');
        } catch (error) {
            console.error('Error adding new post:', error);
            setMessage('Failed to add post. Please try again.');
        }
    };

    return (
        <div className="new-blog-post-container">
            <h2>New Post</h2>
            {message && <p className={message.includes('Failed') ? 'error-message' : 'success-message'}>{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="content">Content</label>
                    <textarea
                        id="content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default NewBlogPost;
