import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Area, AreaChart
} from 'recharts';

function CapitalPlayground() {
    const [startingCapital, setStartingCapital] = useState(1000);
    const [multiplier, setMultiplier] = useState(1);
    const [market, setMarket] = useState('NASDAQ100 CFD');
    const [tradeData, setTradeData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [yAxisDomain, setYAxisDomain] = useState([0, 10000]);
    const token = sessionStorage.getItem('token');

    useEffect(() => {
        const fetchTradeData = async () => {
            const headers = {
                'Authorization': `Bearer ${token}`
            };
            try {
                const tradeData = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/AllTradesNonActive`, { headers });
                setTradeData(tradeData.data);
            } catch (error) {
                console.error('Error fetching trade data', error);
            }
        };

        fetchTradeData();
    }, [token]);

    useEffect(() => {
        if (tradeData.length === 0) return;

        const filteredTrades = tradeData.filter(trade => trade.pair === market);
        let currentCapital = startingCapital;
        let capitalGrowth = [{ tradeNumber: 0, capital: startingCapital }];

        filteredTrades.forEach((trade, index) => {
            const entryPrice = trade.entryPrice;
            const exitPrice = trade.exitPrice;
            //const takeProfit = trade.takeProfit ? JSON.parse(trade.takeProfit) : [];
            console.log(trade);

            let profit = 0;


            if (trade.tradeDirection === "sell") {
                profit = (entryPrice - exitPrice) * 3;
            } else if (trade.tradeDirection === "buy") {
                profit = (exitPrice - entryPrice) * 3;
            }

            profit *= multiplier;
            currentCapital += profit;

            console.log("this is the trade profit", profit);

            capitalGrowth.push({
                tradeNumber: index + 1,
                capital: currentCapital
            });
        });

        setGraphData(capitalGrowth);
        setYAxisDomain([
            Math.min(0) * 0.9,
            Math.max(...capitalGrowth.map(d => d.capital)) * 1.1
        ]);

    }, [startingCapital, multiplier, market, tradeData]);


    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#1e1e1e', padding: '10px', border: '1px solid #ccc', color: '#fff' }}>
                    <p className="label">{`Trade Number: ${payload[0].payload.tradeNumber}`}</p>
                    <p className="label">{`Capital: $${payload[0].value.toFixed(2)}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="capital-playground" style={{ fontFamily: 'Arial, sans-serif', color: '#fff', backgroundColor: '#121212', padding: '2rem' }}>
            <h2 style={{ color: '#007BFF' }}>Capital Playground</h2>
            <div className="controls">
                <div>
                    <label>Starting Capital: ${startingCapital}</label>
                    <input
                        type="range"
                        min="100"
                        max="10000"
                        step="100"
                        value={startingCapital}
                        onChange={e => setStartingCapital(Number(e.target.value))}
                    />
                </div>
                <div>
                    <label>Multiplier: {multiplier}x</label>
                    <input
                        type="range"
                        min="0.1"
                        max="5"
                        step="0.1"
                        value={multiplier}
                        onChange={e => setMultiplier(Number(e.target.value))}
                    />
                </div>
                <div>
                    <label>Market: {market}</label>
                    <select value={market} onChange={e => setMarket(e.target.value)}>
                        <option value="NASDAQ100 CFD">Nasdaq</option>
                        <option value="SP500 CFD">SP500 CFD</option>
                        <option value="GER40 CFD">GER40 CFD</option>
                    </select>
                </div>
            </div>

            <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={graphData}>
                    <defs>
                        <linearGradient id="colorCapital" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="green" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="green" stopOpacity={0} />
                        </linearGradient>
                    </defs>

                    <XAxis dataKey="tradeNumber" tick={{ fill: '#8884d8' }} />
                    <YAxis domain={yAxisDomain} orientation='right' tick={{ fill: '#007BFF' }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend wrapperStyle={{ color: '#8884d8' }} />
                    <Area type="monotone" dataKey="capital" stroke="green" fillOpacity={1} fill="url(#colorCapital)" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}

export default CapitalPlayground;
