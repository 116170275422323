import React from 'react';
import './Hero.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

function Hero() {
    return (
        <div className="hero-container">
            <div className="motto-section">
                <h1>Trade Smarter, Not Harder with AGTT</h1>
                <p>Simplify your trading approach with our innovative solutions and expert insights, ensuring you make informed and profitable decisions.</p>

                <div className="hero-links">
                    <a href="https://discord.gg/vXHZx5cm2U" className="link-button-hero">
                        <FontAwesomeIcon className="icon-hero" icon={faDiscord} /> Discord
                    </a>
                    <a href="#telegram" className="link-button-hero">
                        <FontAwesomeIcon className="icon-hero" icon={faTelegram} /> Telegram
                    </a>
                    <NavLink to="/services" className="link-button-hero">
                        Services
                        <FontAwesomeIcon className="icon-hero-right" icon={faArrowUpRightFromSquare} />
                    </NavLink>
                </div>

            </div>

            <div className="hero-section">
                <p />
            </div>
        </div>
    );
}

export default Hero;
