import React from 'react';
import './PrivacyPolicyPage.css';

function PrivacyPolicyPage() {
    return (
        <div className="privacy-policy-container">
            {/* Introduction Section */}
            <div className="privacy-policy-intro" id="introduction">
                <h1>Privacy Policy</h1>
                <div className="privacy-policy-content">
                    <p>Welcome to AGTT! This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.agtradingtrades.com. By using the Site, you agree to the collection and use of information in accordance with this policy.</p>
                </div>
            </div>

            {/* Information We Collect Section */}
            <div className="privacy-policy-section" id="information-collection">
                <h2>Information We Collect</h2>
                <div className="privacy-policy-content">
                    <p>We collect various types of information in connection with your use of our Site, including:</p>
                    <ul>
                        <li><strong>Personal Data:</strong> We may collect personal information such as your name, email address, and phone number when you register or interact with our services.</li>
                        <li><strong>Usage Data:</strong> We collect data on how you access and use the Site, such as your IP address, browser type, pages visited, and time spent on pages.</li>
                        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to track activity and store certain information to enhance your experience on our Site.</li>
                    </ul>
                </div>
            </div>

            {/* How We Use Your Information Section */}
            <div className="privacy-policy-section" id="how-we-use-info">
                <h2>How We Use Your Information</h2>
                <div className="privacy-policy-content">
                    <p>The information we collect is used in the following ways:</p>
                    <ul>
                        <li>To provide and maintain our Site and services.</li>
                        <li>To notify you about changes or updates to our Site.</li>
                        <li>To provide customer support and respond to inquiries.</li>
                        <li>To analyze and improve our Site and services based on user feedback.</li>
                        <li>To monitor and analyze usage and trends to enhance user experience.</li>
                        <li>To detect, prevent, and address technical issues or fraud.</li>
                    </ul>
                </div>
            </div>

            {/* Data Security Section */}
            <div className="privacy-policy-section" id="data-security">
                <h2>Data Security</h2>
                <div className="privacy-policy-content">
                <p>We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is completely secure. While we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.</p>
                </div>
            </div>

            {/* Data Sharing and Disclosure Section */}
            <div className="privacy-policy-section" id="data-sharing">
                <h2>Data Sharing and Disclosure</h2>
                <div className="privacy-policy-content">
                    <p>We do not sell or rent your personal data to third parties. We may disclose your information in the following circumstances:</p>
                    <ul>
                        <li><strong>With Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and email delivery.</li>
                        <li><strong>For Legal Reasons:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a subpoena or court order).</li>
                        <li><strong>In Business Transfers:</strong> In connection with a merger, acquisition, or any form of business reorganization, your information may be transferred.</li>
                    </ul>
                </div>
            </div>

            {/* User Rights Section */}
            <div className="privacy-policy-section" id="user-rights">
                <h2>User Rights</h2>
                <div className="privacy-policy-content">
                    <p>You have certain rights regarding your personal data, including:</p>
                    <ul>
                        <li><strong>Access:</strong> You have the right to request access to the personal data we hold about you.</li>
                        <li><strong>Correction:</strong> You can request that we correct or update any inaccurate or incomplete personal data.</li>
                        <li><strong>Deletion:</strong> You have the right to request the deletion of your personal data under certain conditions.</li>
                        <li><strong>Objection:</strong> You can object to the processing of your personal data in some cases.</li>
                    </ul>
                    <p>To exercise any of these rights, please contact us at agtradingtrades@gmail.com.</p>
                </div>
            </div>

            {/* Changes to This Privacy Policy Section */}
            <div className="privacy-policy-section" id="changes">
                <h2>Changes to This Privacy Policy</h2>
                <div className="privacy-policy-content">
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                </div>
            </div>

            {/* Contact Information Section */}
            <div className="privacy-policy-section" id="contact">
                <h2>Contact Us</h2>
                <div className="privacy-policy-content">
                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    <ul>
                        <li><strong>By Email:</strong> agtradingtrades@gmail.com</li>
                        <li><strong>By Visiting This Page on Our Website:</strong> agtradingtrades.com/contact</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;
