import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './SignUpPage.css';
import '../../assets/global.css';

function SignUpPage() {
    const [formStatus, setFormStatus] = useState(null);

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPass: '',
        phoneNumber: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        const errors = {};

        // Username validation
        if (!/^[a-zA-Z0-9_]{3,15}$/.test(formData.username)) {
            errors.username = 'Username must be 3-15 characters long and can include letters, numbers, and underscores.';
        }

        // Email validation
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            errors.email = 'Invalid email format.';
        }

        // Phone number validation
        if (!/^[0-9]{10,15}$/.test(formData.phoneNumber)) {
            errors.phoneNumber = 'Phone number must be 10-15 digits long.';
        }

        // Password validation
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/.test(formData.password)) {
            errors.password = 'Password must be at least 8 characters long, include an uppercase letter, a number, and a special character.';
        }

        // Confirm password validation
        if (formData.password !== formData.confirmPass) {
            errors.confirmPass = 'Passwords do not match.';
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const response = await fetch('https://trading-website-5b1479c37ccb.herokuapp.com/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            console.log('Account created Successfully', data);
            setFormStatus(data);
        } catch (error) {
            console.error('Error during fetch operation', error);
            setFormStatus({ status: 'error', message: 'There was an error creating your account, please try again!' });
        }
    };

    return (
        <div className="signup-container">
            <div className="signup-card">
                <h1>Sign Up</h1>
                {formStatus && <p className={`form-status ${formStatus.status}`}>{formStatus.message}</p>}
                <form className="signup-form" onSubmit={handleSubmit}>
                    <label htmlFor="username">Username</label>
                    <input 
                        type="text" 
                        id="username" 
                        name="username" 
                        value={formData.username}
                        onChange={handleChange}
                        className='signup-input'
                        placeholder='Enter your username' 
                        required 
                    />
                    {errors.username && <p className="error-message">{errors.username}</p>}

                    <label htmlFor="email">Email</label>
                    <input 
                        type="email" 
                        id="email" 
                        name="email" 
                        value={formData.email}
                        onChange={handleChange}
                        className='signup-input'
                        placeholder='Enter your email' 
                        required 
                    />
                    {errors.email && <p className="error-message">{errors.email}</p>}

                    <label htmlFor="phoneNumber">Phone number</label>
                    <input 
                        type="text" 
                        id="phoneNumber" 
                        name="phoneNumber" 
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className='signup-input'
                        placeholder='Enter your phone number' 
                        required 
                    />
                    {errors.phoneNumber && <p className="error-message">{errors.phoneNumber}</p>}

                    <label htmlFor="password">Password</label>
                    <input 
                        type="password" 
                        id="password" 
                        name="password" 
                        value={formData.password}
                        onChange={handleChange}
                        className='signup-input'
                        placeholder='Enter your password' 
                        required 
                    />
                    {errors.password && <p className="error-message">{errors.password}</p>}

                    <label htmlFor="confirmPass">Confirm Password</label>
                    <input 
                        type="password" 
                        id="confirmPass" 
                        name="confirmPass" 
                        value={formData.confirmPass}
                        onChange={handleChange}
                        className='signup-input'
                        placeholder='Repeat password' 
                        required 
                    />
                    {errors.confirmPass && <p className="error-message">{errors.confirmPass}</p>}

                    <button type="submit" className="signup-button">Sign Up</button>
                </form>
                <p className="login-text">Already have an account? <NavLink to="/login" activeClassName="active-link">Log in</NavLink></p>
            </div>
        </div>
    );
}

export default SignUpPage;
