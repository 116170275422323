import React, { useState } from 'react';
import axios from 'axios';
import '../../assets/global.css';


function SearchTraders({ onSearch }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSearch = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/searchtrader/${searchTerm}`);
            onSearch(response.data); // Call the onSearch function passed as a prop
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search traders..."
            />
            <button onClick={handleSearch} disabled={loading}>
                Search
            </button>

            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}
        </div>
    );
}

export default SearchTraders;
