import React from 'react';
import Hero from '../../components/Hero/Hero';

import Footer from '../../components/Footer/Footer';
import './HomePage.css';
import '../../assets/global.css';

function HomePage() {
    return (
        <div className="home-page">
            <h1>Your Trusted Partner in Trading</h1>
            <Hero />
            
            <Footer />
        </div>
    );
}

export default HomePage;
