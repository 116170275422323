import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './ForgotPasswordPage.css'; // Create this CSS file

function ForgotPasswordPage() {
    const [email, setEmail] = useState('');
    const [formStatus, setFormStatus] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send a request to your backend to handle the forgot password functionality
            const response = await fetch('YOUR_API_ENDPOINT', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });
            const data = await response.json();
            console.log('Forgot Password Request Success', data);
            setFormStatus({ status: 'success', message: 'Password reset instructions sent to your email.' });
        } catch (error) {
            console.error('Error during forgot password request', error);
            setFormStatus({ status: 'error', message: 'There was an error processing your request. Please try again later.' });
        }
    };

    return (
        <div className="forgot-password-container">
            <div className="forgot-password-card">
                <h1>Reset Password</h1>
                {formStatus && <p className={`form-status ${formStatus.status}`}>{formStatus.message}</p>}
                <form className="forgot-password-form" onSubmit={handleSubmit}>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='forgot-password-input'
                        placeholder='Enter your email'
                        required />
                    <button type="submit" className="forgot-password-button">Submit</button>
                </form>
                <p className="login-text">Remember your password? <NavLink to="/login" activeClassName="active-link">Log in</NavLink></p>
            </div>
        </div>
    );
}

export default ForgotPasswordPage;
