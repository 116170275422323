import React from 'react';
import './AboutPage.css';
import '../../assets/global.css';

function AboutPage() {
    return (
        <div className="about-container">
            {/* Introduction Section */}
            <div className="about-intro" id="about-us">
                <h1>About Us</h1>
                <div className="about-content">
                    <img src="/assets/images/team.jpg" alt="Our Team" className="about-main-image" />
                    <div className="about-text">
                        <p>Welcome to our trading community! We are committed to guiding you through the exciting world of trading, sharing our expertise and insights to help you succeed.</p>
                    </div>
                </div>
            </div>

            {/* Our Trading Style Section */}
            <div className="about-section" id="trading-style">
                <h2>Our Trading Style</h2>
                <div className="about-content reverse">
                    <img src="/assets/images/chart1.jpeg" alt="Our Trading Style" className="about-main-image" />
                    <div className="about-text">
                        <p>We specialize in swing trading, holding positions for several days based on broader timeframes. Our strategy is adaptive and passive, designed to align with market conditions and avoid the pitfalls of frequent intraday trades. We leverage a blend of technical analysis, algorithmic strategies, and seasoned market intuition to inform our trading decisions.</p>
                    </div>
                </div>
            </div>

            {/* Our Approach Section */}
            <div className="about-section" id="approach">
                <h2>Our Approach</h2>
                <div className="about-content">
                    <img src="/assets/images/tradinStyle.jpg" alt="Our Approach" className="about-main-image" />
                    <div className="about-text">
                        <p>Our approach focuses on minimizing risk while maximizing returns. We trade major forex pairs and indices, utilizing specific timeframes that offer the best opportunities. Our updates are clear and concise, saving you time and providing actionable insights that are easy to follow.</p>
                    </div>
                </div>
            </div>

            {/* Live Executions and Updates Section */}
            <div className="about-section" id="live-updates">
                <h2>Live Executions and Updates</h2>
                <div className="about-content reverse">
                    <img src="/assets/images/image5.jpg" alt="Live Executions and Updates" className="about-main-image" />
                    <div className="about-text">
                        <p>Stay in sync with our real-time trade executions, updates, and commentary. Subscribe to our email alerts to receive notifications for new trades and access our comprehensive weekly and monthly performance reports.</p>
                    </div>
                </div>
            </div>

            {/* Trade Details and Alerts Section */}
            <div className="about-section" id="trade-details">
                <h2>Trade Details and Alerts</h2>
                <div className="about-content">
                    <img src="/assets/images/chart.jpg" alt="Trade Details" className="about-main-image" />
                    <div className="about-text">
                        <p>Every trade we make is accompanied by detailed information, including stop losses (SL), take profits (TP), and risk-reward (RR) ratios. Our stop losses are strategically placed to minimize risk and are adjusted as needed. By combining technical analysis with our market expertise, we aim to achieve successful trading outcomes.</p>
                    </div>
                </div>
            </div>

            {/* Example Trade Explanation Section */}
            <div className="about-section" id="example-trade">
                <h2>Example Trade Explanation</h2>
                <div className="about-content reverse">
                    <div className="about-text">
                        <ul className="feature-list">
                            <li className="term">
                                <h3>Red Zone</h3>
                                <p>A potential stop loss or sell entry point.</p>
                            </li>
                            <li className="term">
                                <h3>Green Zone</h3>
                                <p>The target take profit area.</p>
                            </li>
                            <li className="term">
                                <h3>Violet Zone</h3>
                                <p>A key area for support or breakthrough.</p>
                            </li>
                            <li className="term">
                                <h3>Blue Zone</h3>
                                <p>A potential entry or resistance level.</p>
                            </li>
                            <li className="term">
                                <h3>Buy-side Brown Line</h3>
                                <p>Indicates a potential buy entry point.</p>
                            </li>
                            <li className="term">
                                <h3>Sell-side Green Line</h3>
                                <p>Indicates a potential sell entry point.</p>
                            </li>
                        </ul>
                        <p>We provide a detailed tab with all trade information, including entry points, SL, TP, RR, and comments. Learning to read these details is crucial for following our trades effectively.</p>
                        <p>If you are new to trading, we recommend starting with our educational resources. Our tutorials and webinars cover the basics of trading, technical analysis, and risk management to help you build a strong foundation.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;
