import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AWS from 'aws-sdk';
import { ThreeDots } from 'react-loader-spinner';
import '../EditCardPage/EditCardPage.css';
import '../../assets/global.css';

AWS.config.update({
    accessKeyId: "AKIAYS2NSE5D5BQS2V7W",
    secretAccessKey: "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",
    region: 'eu-north-1',
});

const s3 = new AWS.S3();

function NewCardPage() {
    const [formStatus, setFormStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [takeProfits, setTakeProfits] = useState([{ value: '' }]);
    const [images, setImages] = useState([]);
    const [tradeType, setTradeType] = useState('1');
    const [tradeDirection, setTradeDirection] = useState('buy');

    const handleTakeProfitChange = (index, event) => {
        const values = [...takeProfits];
        values[index].value = event.target.value;
        setTakeProfits(values);
    };

    const handleAddTakeProfit = () => {
        setTakeProfits([...takeProfits, { value: '' }]);
    };

    const handleRemoveTakeProfit = index => {
        const values = [...takeProfits];
        values.splice(index, 1);
        setTakeProfits(values);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const takeProfitValues = takeProfits.map(tp => parseFloat(tp.value));
        const formElements = e.target.elements;

        try {
            const uploadPromises = images.map(async (image) => {
                const params = {
                    Bucket: 'trading-website-bucket',
                    Key: image.file.name,
                    Body: image.file,
                    ACL: 'public-read',
                };
                const data = await s3.upload(params).promise();
                return data;
            });

            const imageUrls = await Promise.all(uploadPromises);

            const cardData = {
                pair: formElements.pair.value.toUpperCase(),
                entryPrice: formElements.entryPrice.value,
                stopLoss: formElements.stopLoss.value,
                takeProfits: JSON.stringify(takeProfitValues),
                type: tradeType,
                tradeDirection: tradeDirection,
                isActive: formElements.isActive.value,
                adminComment: formElements.adminComment.value,
                imageUrl: JSON.stringify(imageUrls)
            };

            const token = sessionStorage.getItem('token');
            const response = await axios.post('https://trading-website-5b1479c37ccb.herokuapp.com/addNewCard', cardData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 403) {
                setFormStatus({ status: 'error' });
                setLoading(false);
                return;
            }

            const successMsg = response.data.message;
            const isConfirmed = window.confirm(successMsg + ' Click OK to continue.');

            if (isConfirmed) {
                setFormStatus({ status: 'success', message: successMsg });
                setImages([]);
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Error during Axios operation', error);
            setFormStatus({ status: 'error', message: 'Could not send card data' });
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = async (files) => {
        const newImages = Array.from(files).map(file => ({
            url: URL.createObjectURL(file),
            file: file
        }));

        setImages(prevImages => [...prevImages, ...newImages]);
    };

    const handleDeleteImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    }

    return (
        <div className="edit-card-container">
            <h1>Create New Trade</h1>
            <form onSubmit={handleSubmit} className="edit-card-form">
                <label>
                    Pair:
                    <input type="text" name="pair" required />
                </label>
                <label>
                    Entry Price:
                    <input type="number" name="entryPrice" step="any" />
                </label>
                <label>
                    Stop Loss:
                    <input type="number" name="stopLoss" step="any" />
                </label>

                <label>Take Profits:</label>
                {takeProfits.map((takeProfit, index) => (
                    <div key={index} className="take-profit-input">
                        <input
                            type="number"
                            step="any"
                            value={takeProfit.value}
                            onChange={event => handleTakeProfitChange(index, event)}
                        />
                        <button type="button" onClick={() => handleRemoveTakeProfit(index)}>
                            Remove
                        </button>
                    </div>
                ))}
                <button type="button" onClick={handleAddTakeProfit} className="add-take-profit">
                    Add Take Profit
                </button>

                <label htmlFor="tradeType">Trade Type:</label>
                <select name="tradeType" id="tradeType" onChange={(event) => setTradeType(event.target.value)} >
                    <option value="1">Forex</option>
                    <option value="2">Indices</option>
                </select>

                <label htmlFor="tradeDirection">Trade Direction:</label>
                <select name="tradeDirection" id="tradeDirection" onChange={(event) => setTradeDirection(event.target.value)} >
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                </select>

                <label htmlFor="isActive">Is signal active:</label>
                <select name="isActive" id="isActive" >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
                <div className="image-upload-container">
                    <div className="image-preview-container">
                        {images.map((image, index) => (
                            <div key={index} className="image-preview">
                                <img src={image.url} alt={`Preview ${index}`} />
                                <button type="button" onClick={() => handleDeleteImage(index)}>Delete</button>
                            </div>
                        ))}
                    </div>
                    <div className="drag-drop-area">
                        <input type="file" accept="image/*" onChange={(e) => handleImageChange(e.target.files)} multiple />
                        <p>Drag & Drop Images Here</p>
                    </div>
                </div>

                <label htmlFor="adminComment">
                    Admin Comment:
                    <textarea name="adminComment"></textarea>
                </label>

                <button type="submit" className="submit-button">Create Card</button>
                {loading && (
                    <div className="loading-spinner">
                        <ThreeDots type="ThreeDots" color="#00BFFF" height={80} width={80} />
                    </div>
                )}
            </form>
            {formStatus && <p className={`form-status ${formStatus.status}`}>{formStatus.message}</p>}

        </div>
    );
}

export default NewCardPage;
