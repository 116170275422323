import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProfilePage.css';
import PaymentPage from '../PaymentPage/PaymentPage';

function ProfilePage() {
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const token = sessionStorage.getItem('token');
                const headers = { 'Authorization': `Bearer ${token}` };
                const userDataResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/getUserProfile`, { headers });
                setUserData(userDataResponse.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);



    let formattedRegistrationDate;
    if (userData && userData.user && userData.user.registrationDate) {
        const registrationDate = new Date(userData.user.registrationDate);
        formattedRegistrationDate = registrationDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const userEmail = () => {
        let link = "https://billing.stripe.com/p/login/test_3csdRU3Z42eLa525kk?prefilled_email=";
        if (userData.user.email) {
            let email = userData.user.email;
            let emailParts = email.split('@');
            let emailDomain = emailParts[1];
            let emailUsername = emailParts[0];
            console.log(emailUsername);
            console.log(emailDomain);
            link += emailUsername + "%40" + emailDomain;
            console.log(link);
            return <div><a href={link} target="_blank" rel="noopener noreferrer">View Stripe Billing</a></div>;
        } else {
            return '';
        }
    };

    userEmail();

    return (
        <div className="profile-container">
            <div className="user-details">
                <p><strong>Username:</strong> {userData.user.username}</p>
                <p><strong>Email:</strong> {userData.user.email}</p>
                <p><strong>Phone Number:</strong> {userData.user.phoneNumber}</p>
                <p><strong>Registration Date:</strong> {formattedRegistrationDate}</p>
                {userData.subscriptions && userData.subscriptions.length > 0 && (
                    <p><strong>Subscription Type:</strong> {userData.subscriptions[0].typeName}</p>
                )}
                <div className="subscription-actions">
                    
                        <PaymentPage />
                    
                </div>
            </div>

        </div>
    );
}

export default ProfilePage;
